import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import {
    TextField, Select, FormHelperText, Input, InputAdornment, Snackbar, Box, Button
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import { moneyFunction } from '../../../constants/moneyFunction';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';

import useStyles from './SubMenuPage.styles';
import Text from '../../../components/Text';
import PageContainer from '../../../components/PageContainer';
import TopBarDetail from './TopBarDetail';
import SubMenuTabBar from './SubMenuTabBar';
import { getReferencesById, getShokeeperDetailById } from '../ShopPage.actions';
import { getCreditLineIds } from '../../../reducers/users.action';

import CreditInformationPage from './CreditInformation/CreditInformationPage';
import { getCreditDetail } from './CreditInformation/CreditInformation.actions';
import TransactionPage from './Transactions/TransactionsPage';
import CollectionPage from './Collections/CollectionPage';
import History from './History/History';
import GeneralPage from './General/GeneralPage';
import LateAmount from '../../LateAmount/LateAmount';
import PaymentAgreements from '../../PaymentAgreements';

const SubMenuPage = (props) => {
    const { creditLineId, shopId } = props.match.params;
    const [subMenu, setSubMenu] = useState('general')
    const [dataIds, setDataIds] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        shokeeper_detail,
    } = useSelector((state) => state.shopState);

    const {
        references_detail,
    } = useSelector((state) => state.shopState);

    const {
        credit_line_shop_detail,
    } = useSelector((state) => state.creditInformationState);

    const {
        getCreditLineIdsData
    } = useSelector((state) => state.usersReducer);
    
    useEffect(() => {
        dispatch(getCreditDetail(creditLineId))
    }, [creditLineId]);

    useEffect(() => {
        dispatch(getShokeeperDetailById(shopId))
        dispatch(getReferencesById(shopId))
        dispatch(getCreditLineIds(shopId))
    }, [shopId]);

    useEffect(() => {
        if(Object.keys(getCreditLineIdsData).length !== 0) {
            // Process and sort the credit line data
            const processedData = getCreditLineIdsData.data
                .map(item => ({
                    credit_line_id: item.credit_line_id,
                    created_at: item.created_at
                }))
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            setDataIds(processedData);
        }
    }, [getCreditLineIdsData]);
    
    const subMenuDetail = () => {
        switch (subMenu) {
            case 'transactions':
                return <TransactionPage creditLineId={creditLineId} credit_line_shop_detail={credit_line_shop_detail} accountId={shopId} dataIds={dataIds}/>
            case 'collections':
                return <CollectionPage shopId={shopId} />
            case 'creditInformation':
                return <CreditInformationPage creditLineId={creditLineId} dataIds={dataIds} credit_line_shop_detail={credit_line_shop_detail}/>
            case 'historyBlocked':
                return <History creditLineId={creditLineId} dataIds={dataIds}/>
            case 'lateAmount':
                return <LateAmount creditLineId={creditLineId} dataIds={dataIds}/>
            case 'paymentAgreements':
                return <PaymentAgreements creditLineId={creditLineId} />
            case 'general':
                return <GeneralPage
                    shokeeper_detail={shokeeper_detail}
                    credit_line_shop_detail={credit_line_shop_detail}
                    accountId={shopId}
                    references_detail={references_detail}
                />
            default:
                return <TransactionPage creditLineId={creditLineId} credit_line_shop_detail={credit_line_shop_detail} accountId={shopId} dataIds={dataIds}/>
        }
    }

    return (
        <PageContainer>
            <Link to="/shop/" className={classes.controls}>
                <ArrowBackIcon />
                <Text>Regresar</Text>
            </Link>
            <TopBarDetail shokeeper_detail={shokeeper_detail?.data || shokeeper_detail} credit_line_shop_detail={credit_line_shop_detail} />
            <SubMenuTabBar
                creditLineId={creditLineId}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
            />
            {subMenuDetail()}
        </PageContainer>
    );
};

export default SubMenuPage;
