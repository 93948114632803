export const monthNamesFull = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];

export const monthNames = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SEP",
  "OCT",
  "NOV",
  "DIC",
];

export const daysOfMonth = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 18, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 28, 30,
];

export const dayNames = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

export const dayNamesMap = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo",
};

export const transactionType = {
  Buy: "Compra",
  Pay: "Abono YoFio",
  Wd: "Liquidación",
  Dp: "Nota de cobro",
};

export const statusType = {
  APPROVED: "Aprobado",
  PENDING: "Pendiente",
  DONE: "Hecho",
  PAID: "Pagado",
  COLLECTED: "Cobrado",
  LATE: "Atrasado",
  INACTIVE: "Inactivo",
  CURRENT: "Al corriente",
  REVIEWING: "Revisando",
  "PARTLY PAID": "Parcialmente pagado",
  "PARTLY COLLECTED": "Parcialmente cobrado",
};

export const colorStatus = {
  APPROVED: "#0253cc",
  PENDING: "#ee4e52",
  DONE: "#009805",
  PAID: "#009805",
  COLLECTED: "#0253cc",
  LATE: "#ee4e52",
  INACTIVE: "#ee4e52",
  CURRENT: "#009805",
  REVIEWING: "Revisando",
  "PARTLY PAID": "#0253cc",
  "PARTLY COLLECTED": "#0253cc",
};

export const preferredPaymentDayOptions = [
  {
    title: "Lunes",
    value: "MONDAY",
  },
  {
    title: "Martes",
    value: "TUESDAY",
  },
  {
    title: "Miércoles",
    value: "WEDNESDAY",
  },
  {
    title: "Jueves",
    value: "THURSDAY",
  },
  {
    title: "Viernes",
    value: "FRIDAY",
  },
  {
    title: "Sábado",
    value: "SATURDAY",
  },
  {
    title: "Domingo",
    value: "SUNDAY",
  },
];

export const paymentDayTitle = {
  MONDAY: "Lunes",
  TUESDAY: "Martes",
  WEDNESDAY: "Miércoles",
  THURSDAY: "Jueves",
  FRIDAY: "Viernes",
  SATURDAY: "Sábado",
  SUNDAY: "Domingo",
};

export const termTypeTitles = {
  WEEKLY: "Semanal",
  BIWEEKLY: "Catorcenal",
  MONTHLY: "Mensual",
};

export const banks = [
  {
    title: "ABC Capital",
    value: "abc capital",
    code: null,
  },
  {
    title: "Accendo Banco",
    value: "accendo banco",
    code: null,
  },
  {
    title: "Actinver",
    value: "actinver",
    code: null,
    isActive: true,
  },
  {
    title: "Afirme",
    value: "afirme",
    code: "BAFIR",
    isActive: true,
  },
  {
    title: "Azteca",
    value: "azteca",
    code: "BAZTE",
    isActive: true,
  },
  {
    title: "BANAMEX",
    value: "banamex",
    code: "BANAM",
    isActive: true,
  },
  {
    title: "BanBajío",
    value: "banbajio",
    code: "BAJIO",
    isActive: true,
  },
  {
    title: "Banco Base",
    value: "banco base",
    code: null,
  },
  {
    title: "BanCoppel",
    value: "bancoppel",
    code: "COPEL",
    isActive: true,
  },
  {
    title: "Bancrea",
    value: "bancrea",
    code: null,
  },
  {
    title: "Banjercito",
    value: "banjercito",
    code: null,
    isActive: true,
  },
  {
    title: "Banorte",
    value: "banorte",
    code: "BBANO",
    isActive: true,
  },
  {
    title: "Banregio",
    value: "banregio",
    code: "BANRE",
    isActive: true,
  },
  {
    title: "Bansí",
    value: "bansi",
    code: "BANSI",
  },
  {
    title: "BBVA",
    value: "bbva",
    code: "BACOM",
    isActive: true,
  },
  {
    title: "CIBanco",
    value: "cibanco",
    code: null,
  },
  {
    title: "Compartamos",
    value: "compartamos",
    code: "BCOMP",
    isActive: true,
  },
  {
    title: "Consubanco",
    value: "consubanco",
    code: "CONSU",
    isActive: true,
  },
  {
    title: "Famsa",
    value: "famsa",
    code: "FAMSA",
  },
  {
    title: "Finterra",
    value: "finterra",
    code: null,
  },
  {
    title: "Forjadores",
    value: "forjadores",
    code: null,
  },
  {
    title: "HSBC",
    value: "hsbc",
    code: "BITAL",
    isActive: true,
  },
  {
    title: "Inbursa",
    value: "inbursa",
    code: "BINBU",
    isActive: true,
  },
  {
    title: "Invex",
    value: "invex",
    code: "BINVE",
  },
  {
    title: "Mifel",
    value: "mifel",
    code: "MIFEL",
    isActive: true,
  },
  {
    title: "Monex",
    value: "monex",
    code: "CMCA",
  },
  {
    title: "Multiva",
    value: "multiva",
    code: "MULTI",
  },
  {
    title: "PagaTodo",
    value: "pagatodo",
    code: null,
    isActive: true,
  },
  {
    title: "Sabadell",
    value: "sabadell",
    code: null,
  },
  {
    title: "Santander",
    value: "santander",
    code: "BANME",
    isActive: true,
  },
  {
    title: "Scotiabank",
    value: "scotiabank",
    code: "COMER",
    isActive: true,
  },
  {
    title: "Stp",
    value: "stp",
    code: null,
    isActive: true,
  },
  {
    title: "Ve por Más",
    value: "ve por mas",
    code: "DRESD",
    isActive: true,
  },
];

export const layoutFieldDescriptors = {
  layout: {
    length: 5,
  },
  chargeAccount: {
    length: 18,
    align: "right",
    padding: " ",
  },
  depositAccount: {
    length: 20,
    align: "right",
    padding: " ",
  },
  bankCode: {
    length: 5,
    type: "bankCode",
  },
  beneficiary: {
    length: 40,
    align: "left",
    padding: " ",
  },
  branchOfficeCode: {
    length: 4,
  },
  amount: {
    length: 18,
    padding: "0",
    align: "right",
    type: "currency",
  },
  cityCode: {
    length: 5,
  },
  concept: {
    type: "concept",
    align: "left",
    padding: " ",
    length: 40,
  },
  orderingReference: {
    type: "accountId",
    align: "right",
    length: 7,
  },
  beneficiaryEmail: {
    align: "left",
    padding: " ",
    length: 39,
  },
  controlDigit: {
    required: true,
    length: 1,
  },
  dateOfApplication: {
    length: 8,
    type: "date",
    format: "DDMMAAAA",
  },
};

export const headCells = [
  {
    id: "products",
    label: "Productos",
  },
  {
    id: "inventoryForm",
    label: "Inventario llenado",
  },
  {
    id: "inventoryCalculated",
    label: "Inventario calculado",
  },
  {
    id: "alert",
    label: "Alerta",
  },
];

export const headCellBuyDealer = [
  {
    id: "business_name",
    label: "Nombre del negocio",
  },
  {
    id: "id_business",
    label: "ID Negocio",
  },
  {
    id: "owner",
    label: "Dueño",
  },
  {
    id: "phone",
    label: "Teléfono",
  },
  {
    id: "purchase_amount",
    label: "Monto de compra",
  },
  {
    id: "date_amount",
    label: "Fecha de compra",
  },
  {
    id: "expiration",
    label: "Caducidad",
  },
  {
    id: "state",
    label: "Estado",
  },
  {
    id: "detail",
    label: "Detalle",
  },
];

export const headCellReviewVoucher = [
  {
    id: "business_name",
    label: "Nombre del negocio",
  },
  {
    id: "id_business",
    label: "ID Negocio",
  },
  {
    id: "owner",
    label: "Dueño",
  },
  {
    id: "phone",
    label: "Teléfono",
  },
  {
    id: "state",
    label: "Estado",
  },
  {
    id: "detail",
    label: "Detalle",
  },
];

export const headCellMetadataReviewVoucher = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "item",
    label: "Item",
  },
  {
    id: "amount",
    label: "Cantidad",
  },
  {
    id: "unit_price",
    label: "Precio unitario",
  },
  {
    id: "total_price",
    label: "Precio total",
  },
  {
    id: "category",
    label: "Categoría",
  },
];

export const headCellMatiVerification = [
  {
    id: "id_business",
    label: "ID Tienda",
  },
  {
    id: "business_name",
    label: "Nombre del negocio",
  },
  {
    id: "phone_person",
    label: "Teléfono del titular",
  },
  {
    id: "name_person",
    label: "Nombre de la persona",
  },
  {
    id: "rfc",
    label: "RFC",
  },
  {
    id: "curp",
    label: "CURP",
  },
  {
    id: "kind_person",
    label: "Tipo de persona",
  },
  {
    id: "status",
    label: "Estatus",
  },
  {
    id: "mati",
    label: "MATI",
  },
  {
    id: "slope_ratio",
    label: "Razón de pendiente",
  },
  {
    id: "action",
    label: "Acción",
  },
];

export const headCellDatingHistory = [
  {
    id: "appointment_type",
    label: "Tipo de Cita",
  },
  {
    id: "appointment_reason",
    label: "Motivo de Cita",
  },
  {
    id: "schedule_date",
    label: "Fecha agendada",
  },
  {
    id: "customer_name",
    label: "Nombre del cliente",
  },
  {
    id: "appointment_status",
    label: "Estatus de la Cita",
  },
  {
    id: "result",
    label: "Resultado de la Cita",
  },
  {
    id: "comments",
    label: "Comentarios",
  },
];

export const headCellsTreasuryDealer = [
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "País",
  },
  {
    id: "account_type_id",
    numeric: false,
    disablePadding: false,
    label: "Tipo de crédito",
  },
  {
    id: "id_shop",
    numeric: false,
    disablePadding: false,
    label: "ID usuario",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Fecha de solicitud",
  },
  {
    id: "sk_name",
    numeric: false,
    disablePadding: false,
    label: "Nombre de usuario",
  },
  {
    id: "beneficiary_name",
    numeric: false,
    disablePadding: false,
    label: "Nombre de beneficiario",
  },
  {
    id: "sk_id",
    numeric: false,
    disablePadding: false,
    label: "Documento de usuario",
  },
  {
    id: "beneficiary_id",
    numeric: false,
    disablePadding: false,
    label: "Documento de beneficiario",
  },
  {
    id: "shop_name",
    numeric: false,
    disablePadding: false,
    label: "Tienda",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "bank",
    numeric: false,
    disablePadding: false,
    label: "Banco",
  },
  {
    id: "acount",
    numeric: true,
    disablePadding: false,
    label: "Cuenta",
  },
  {
    id: "account_type_id",
    numeric: false,
    disablePadding: false,
    label: "Validación cuenta bancaria",
  },
  {
    id: "total_to_disburse",
    numeric: true,
    disablePadding: false,
    label: "Total a desembolsar",
  },
  {
    id: "amount_paid",
    numeric: true,
    disablePadding: false,
    label: "Monto pagado",
  },
  {
    id: "id_shop_ref",
    numeric: true,
    disablePadding: false,
    label: "ID Tienda",
  },
  {
    id: "action",
    label: "Acción",
  },
  {
    id: "action",
    label: "Acción",
  },
  {
    id: "action",
    label: "Acción",
  },
];

export const headCellReactivateCollateral = [
  {
    id: "check",
    label: "",
  }, {
    id: "name",
    label: "Nombre"
  }, {
    id: "phone",
    label: "Télefono"
  }, {
    id: "rfc",
    label: "RFC"
  }, {
    id: "action",
    label: "Acción"
  }
];

export const massiveTransactionLayouts = {
  LTX05: {
    length: 203,
    fields: [
      {
        fieldName: "layout",
        fieldRules: layoutFieldDescriptors.layout,
        required: true,
        value: "LTX05",
        position: [1, 5],
      },
      {
        fieldName: "chargeAccount",
        fieldRules: layoutFieldDescriptors.chargeAccount,
        position: [6, 23],
        required: true,
        value: "65508270300",
      },
      {
        fieldName: "depositAccount",
        fieldRules: layoutFieldDescriptors.depositAccount,
        position: [24, 43],
        required: true,
      },
      {
        fieldName: "bankCode",
        fieldRules: layoutFieldDescriptors.bankCode,
        position: [44, 48],
        required: true,
      },
      {
        fieldName: "beneficiary",
        fieldRules: layoutFieldDescriptors.beneficiary,
        position: [49, 88],
        required: true,
      },
      {
        fieldName: "branchOfficeCode",
        fieldRules: layoutFieldDescriptors.branchOfficeCode,
        position: [89, 92],
        required: true,
        value: "0000",
      },
      {
        fieldName: "amount",
        fieldRules: layoutFieldDescriptors.amount,
        position: [93, 110],
        required: true,
      },
      {
        fieldName: "cityCode",
        fieldRules: layoutFieldDescriptors.cityCode,
        position: [111, 115],
        value: "00000",
      },
      {
        fieldName: "concept",
        fieldRules: layoutFieldDescriptors.concept,
        position: [116, 155],
        required: false,
      },
      {
        fieldName: "orderingReference",
        fieldRules: layoutFieldDescriptors.orderingReference,
        position: [156, 162],
        required: false,
      },
      {
        fieldName: "beneficiaryEmail",
        fieldRules: layoutFieldDescriptors.beneficiaryEmail,
        position: [163, 202],
      },
      {
        fieldName: "controlDigit",
        fieldRules: layoutFieldDescriptors.controlDigit,
        position: [203, 203],
        value: "1",
      },
    ],
  },
  LTX07: {
    length: 150,
    fields: [
      {
        fieldName: "layout",
        fieldRules: layoutFieldDescriptors.layout,
        required: true,
        value: "LTX07",
        position: [1, 5],
      },
      {
        fieldName: "chargeAccount",
        fieldRules: layoutFieldDescriptors.chargeAccount,
        position: [6, 23],
        required: true,
        value: "65508270300",
      },
      {
        fieldName: "depositAccount",
        fieldRules: layoutFieldDescriptors.depositAccount,
        position: [24, 43],
        required: true,
      },
      {
        fieldName: "amount",
        fieldRules: layoutFieldDescriptors.amount,
        position: [44, 61],
        required: true,
      },
      {
        fieldName: "concept",
        fieldRules: layoutFieldDescriptors.concept,
        position: [62, 101],
        required: false,
      },
      {
        fieldName: "dateOfApplication",
        fieldRules: layoutFieldDescriptors.dateOfApplication,
        position: [102, 110],
        required: true,
      },
      {
        fieldName: "beneficiaryEmail",
        fieldRules: layoutFieldDescriptors.beneficiaryEmail,
        position: [111, 150],
      },
    ],
  },
};

export const listBanks = [
  {
    id: "actinver",
    name: "Actinver"
  },
  {
    id: "afirme",
    name: "Afirme"
  },
  {
    id: "azteca",
    name: "Azteca"
  },
  {
    id: "banamex",
    name: "Banamex"
  },
  {
    id: "banbajio",
    name: "Banbajio"
  },
  {
    id: "bancoppel",
    name: "Bancoppel"
  },
  {
    id: "banjercito",
    name: "Banjercito"
  },
  {
    id: "banorte",
    name: "Banorte"
  },
  {
    id: "banregio",
    name: "Banregio"
  },
  {
    id: "bbva",
    name: "BBVA"
  },
  {
    id: "compartamos",
    name: "Compartamos"
  },
  {
    id: "consubanco",
    name: "Consubanco"
  },
  {
    id: "hsbc",
    name: "HSBC"
  },
  {
    id: "inbursa",
    name: "Inbursa"
  },
  {
    id: "mifel",
    name: "Mifel"
  },
  {
    id: "pagatodo",
    name: "PagaTodo"
  },
  {
    id: "santander",
    name: "Santander"
  },
  {
    id: "scotiabank",
    name: "Scotiabank"
  },
  {
    id: "stp",
    name: "Stp"
  },
  {
    id: "ve por mas",
    name: "Ve por mas"
  }
]